import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useEtc } from "../context/EtcContext";
// import { cocktails } from "../data/cocktails";

const DrinksContent = () => {
  const { i18n } = useTranslation();
  const {
    state: { cocktailsData },
  } = useEtc();
  return (
    <DrinksDiv>
      <div>
        {/* <h3>-Vodka-</h3>
        <span>{i18n.language === "en" ? "gl / btl" : "verre / bout."}</span>
        {spirits?.map(
          (el, ind) =>
            el?.category === "Vodka" && (
              <div
                key={el?.id}
              >
                <span>
                  <span>{el?.name}</span>
                </span>
                <span>
                  {i18n.language === "en"
                    ? `$${el?.price_gl} / $${el?.price_btl}`
                    : `${el?.price_gl}$ / ${el?.price_btl}$`}
                </span>
              </div>
            )
        )}
        <h3>-Gin-</h3>
        <span>{i18n.language === "en" ? "gl / btl" : "verre / bout."}</span>
        {spirits?.map(
          (el, ind) =>
            el?.category === "Gin" && (
              <div
                key={el?.id}
              >
                <span>
                  <span>{el?.name}</span>
                </span>
                <span>
                  {i18n.language === "en"
                    ? `$${el?.price_gl} / $${el?.price_btl}`
                    : `${el?.price_gl}$ / ${el?.price_btl}$`}
                </span>
              </div>
            )
        )}
        <h3>-{i18n.language === "en" ? "Rum" : "Rhum"}-</h3>
        <span>{i18n.language === "en" ? "gl / btl" : "verre / bout."}</span>
        {spirits?.map(
          (el, ind) =>
            el?.category === "Rhum / Rum" && (
              <div
                key={el?.id}
              >
                <span>
                  <span>{el?.name}</span>
                </span>
                <span>
                  {i18n.language === "en"
                    ? `$${el?.price_gl} / $${el?.price_btl}`
                    : `${el?.price_gl}$ / ${el?.price_btl}$`}
                </span>
              </div>
            )
        )}
        <h3>-Tequila-</h3>
        <span>{i18n.language === "en" ? "gl / btl" : "verre / bout."}</span>
        {spirits?.map(
          (el, ind) =>
            el?.category === "Tequila" && (
              <div
                key={el?.id}
              >
                <span>
                  <span>{el?.name}</span>
                </span>
                <span>
                  {i18n.language === "en"
                    ? `$${el?.price_gl} / $${el?.price_btl}`
                    : `${el?.price_gl}$ / ${el?.price_btl}$`}
                </span>
              </div>
            )
        )}
        <h3>-{i18n.language === "en" ? "Rye" : "Bourbon"}-</h3>
        <span>{i18n.language === "en" ? "gl / btl" : "verre / bout."}</span>
        {spirits?.map(
          (el, ind) =>
            el?.category === "Bourbon/Rye" && (
              <div
                key={el?.id}
              >
                <span>
                  <span>{el?.name}</span>
                </span>
                <span>
                  {i18n.language === "en"
                    ? `$${el?.price_gl} / $${el?.price_btl}`
                    : `${el?.price_gl}$ / ${el?.price_btl}$`}
                </span>
              </div>
            )
        )}
        <h3>
          -{i18n.language === "en" ? "Irish Whiskey" : "Whiskey Irlandais"}-
        </h3>
        <span>{i18n.language === "en" ? "gl / btl" : "verre / bout."}</span>
        {spirits?.map(
          (el, ind) =>
            el?.category === "Whiskey Irlandais / Irish Whiskey" && (
              <div
                key={el?.id}
              >
                <span>
                  <span>{el?.name}</span>
                </span>
                <span>
                  {i18n.language === "en"
                    ? `$${el?.price_gl} / $${el?.price_btl}`
                    : `${el?.price_gl}$ / ${el?.price_btl}$`}
                </span>
              </div>
            )
        )}
        <h3>-Scotch-</h3>
        <span>{i18n.language === "en" ? "gl / btl" : "verre / bout."}</span>
        {spirits?.map(
          (el, ind) =>
            el?.category === "Scotch" && (
              <div
                key={el?.id}
              >
                <span>
                  <span>{el?.name}</span>
                </span>
                <span>
                  {i18n.language === "en"
                    ? `$${el?.price_gl} / $${el?.price_btl}`
                    : `${el?.price_gl}$ / ${el?.price_btl}$`}
                </span>
              </div>
            )
        )}
        <h3>-Brandy-</h3>
        <span>{i18n.language === "en" ? "gl / btl" : "verre / bout."}</span>
        {spirits?.map(
          (el, ind) =>
            el?.category === "Brandy" && (
              <div
                key={el?.id}
              >
                <span>
                  <span>{el?.name}</span>
                </span>
                <span>
                  {i18n.language === "en"
                    ? `$${el?.price_gl} / $${el?.price_btl}`
                    : `${el?.price_gl}$ / ${el?.price_btl}$`}
                </span>
              </div>
            )
        )}
        <h3>-Cognac-</h3>
        <span>{i18n.language === "en" ? "gl / btl" : "verre / bout."}</span>
        {spirits?.map(
          (el, ind) =>
            el?.category === "Cognac" && (
              <div
                key={el?.id}
              >
                <span>
                  <span>{el?.name}</span>
                </span>
                <span>
                  {i18n.language === "en"
                    ? `$${el?.price_gl} / $${el?.price_btl}`
                    : `${el?.price_gl}$ / ${el?.price_btl}$`}
                </span>
              </div>
            )
        )}
        <h3>-Grappa-</h3>
        <span>{i18n.language === "en" ? "gl / btl" : "verre / bout."}</span>
        {spirits?.map(
          (el, ind) =>
            el?.category === "Grappa" && (
              <div
                key={el?.id}
              >
                <span>
                  <span>{el?.name}</span>
                </span>
                <span>
                  {i18n.language === "en"
                    ? `$${el?.price_gl} / $${el?.price_btl}`
                    : `${el?.price_gl}$ / ${el?.price_btl}$`}
                </span>
              </div>
            )
        )}
        <h3>-Amaro-</h3>
        <span>{i18n.language === "en" ? "gl / btl" : "verre / bout."}</span>
        {spirits?.map(
          (el, ind) =>
            el?.category === "Amaro" && (
              <div
                key={el?.id}
              >
                <span>
                  <span>{el?.name}</span>
                </span>
                <span>
                  {i18n.language === "en"
                    ? `$${el?.price_gl} / $${el?.price_btl}`
                    : `${el?.price_gl}$ / ${el?.price_btl}$`}
                </span>
              </div>
            )
        )} */}
        <h3>-Cocktails-</h3>
        <span></span>
        {cocktailsData?.map(
          (el, ind) =>
            el?.category === "Cocktails" && (
              <div
                key={el?.id}
                // className={menuItemId === el.id ? "active-item" : undefined}
              >
                <span>
                  <span>{i18n.language === "en" ? el?.name : el?.name_fr}</span>
                  <p>
                    {i18n.language === "en"
                      ? el?.description
                      : el?.description_fr}
                  </p>
                </span>
                <span>
                  {i18n.language === "en" ? `$${el?.price}` : `${el?.price}$`}
                </span>
              </div>
            )
        )}
        <h3>-Mocktail-</h3>
        <span></span>
        {cocktailsData?.map(
          (el, ind) =>
            el?.category === "Mocktail" && (
              <div
                key={el?.id}
                // className={menuItemId === el.id ? "active-item" : undefined}
              >
                <span>
                  <span>{i18n.language === "en" ? el?.name : el?.name_fr}</span>
                  <p>
                    {i18n.language === "en"
                      ? el?.description
                      : el?.description_fr}
                  </p>
                </span>
                <span>
                  {i18n.language === "en" ? `$${el?.price}` : `${el?.price}$`}
                </span>
              </div>
            )
        )}
        {/* <h3>-{i18n.language === "en" ? "Beer" : "Bière"}-</h3>
        <span></span>
        {drinks?.map(
          (el, ind) =>
            el?.category === "Beer" && (
              <div
                key={el?.id}
              >
                <span>
                  <span>{el?.name}</span>
                </span>
                <span>
                  {i18n.language === "en" ? `$${el?.price}` : `${el?.price}$`}
                </span>
              </div>
            )
        )}
        <h3>-{i18n.language === "en" ? "Water/juice/pop" : "Eau/jus/soda"}-</h3>
        <span></span>
        {drinks?.map(
          (el, ind) =>
            el?.category === "Water/juice/pop" && (
              <div
                key={el?.id}
              >
                <span>
                  <span>{el?.name}</span>
                </span>
                <span>
                  {i18n.language === "en" ? `$${el?.price}` : `${el?.price}$`}
                </span>
              </div>
            )
        )}
        <h3>-{i18n.language === "en" ? "Coffee" : "Café"}-</h3>
        <span></span>
        {drinks?.map(
          (el, ind) =>
            el?.category === "Coffee" && (
              <div
                key={el?.id}
              >
                <span>
                  <span>{el?.name}</span>
                </span>
                <span>
                  {i18n.language === "en" ? `$${el?.price}` : `${el?.price}$`}
                </span>
              </div>
            )
        )} */}
        <div>
          <a
            href={`/pdf/etc-cocktail-menu-8.5x11-print.pdf`}
            rel="noopener noreferrer"
            target="_blank"
          >
            {i18n.language === "en" ? "Drinks menu" : "Menu des boissons"}
          </a>
          <a
            href={`/pdf/ETC_SUMMER_DRINKS_${i18n.language === "en" ? "EN" : "FR"}.pdf`}
            rel="noopener noreferrer"
            target="_blank"
          >
            {i18n.language === "en" ? "Spirits menu" : "Menu des spiritueux"}
          </a>
          {/* <a
            href={`/pdf/ETC_COCKTAIL_${
              i18n.language === "en" ? "EN" : "FR"
            }.pdf`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {i18n.language === "en" ? "Cocktails menu" : "Menu des cocktails"}
          </a> */}
          {/* <a
            href="/pdf/PG_WINE_MENU.pdf"
            rel="noopener noreferrer"
            target="_blank"
          >
            {i18n.language === "en" ? "Wine menu" : "Menu des vins"}
          </a> */}
        </div>
      </div>
    </DrinksDiv>
  );
};
const DrinksDiv = styled.div`
  height: 100%;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px;
  & > div {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-bottom: 35px;
    overflow-y: scroll;
    scroll-behavior: smooth;
    ::-webkit-scrollbar {
      width: 2px;
    }
    /* Track */
    ::-webkit-scrollbar-track {
      border-radius: 20px;
    }
    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: rgba(255, 255, 255, 0.3);
      border-radius: 20px;
    }
    /* Handle on hover */
    /* ::-webkit-scrollbar-thumb:hover {
    background: var(--lightgrey);
  } */
    & > h3 {
      text-align: center;
      font-weight: 500;
      margin-top: 36px;
      margin-bottom: 24px;
      font-size: 36px;
    }
    & > span {
      text-align: right;
      margin-right: 16px;
    }
    & > div {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      /* cursor: pointer; */
      padding: 16px 8px;
      margin-right: 8px;
      transition: 0.4s all;
      gap: 5px;
      & > span {
        /* max-width: 200px; */
        & > span {
          font-size: 16px;
          color: var(--white);
        }
        & > p {
          margin-top: 10px;
          font-weight: lighter;
          font-size: 16px;
        }
      }
    }
    & > div:last-child {
      justify-content: center;
      /* flex-wrap: wrap; */
      align-items: center;
      margin-top: 36px;
      gap: 10px;
      & > a {
        align-self: center;
        text-align: center;
        border: none;
        padding: 10px 25px;
        font-size: 18px;
        color: var(--white);
        border: 1px solid var(--white);
        background: none;
        outline: none;
        border-radius: 20px;
        transition: opacity 0.4s ease-in;
        &:hover {
          transition: all 0.3s ease-in;
          background: var(--white);
          color: var(--black);
        }
      }
    }
    /* .active-item {
      background: var(--secondary);
      border-radius: 10px;
    } */
  }
  @media screen and (max-width: 991px) {
    /* start of phone and tablet styles */
    padding: 0;
    & > div {
      /* max-height: 220px; */
      & > h3 {
        font-size: 28px;
        margin-top: 40px;
        margin-bottom: 28px;
      }
      & > span {
        margin: 0;
      }
      & > div {
        padding: 6px 0;
        margin: 0;
        & > span {
          & > span {
            font-size: 16px;
          }
          & > p {
            font-size: 14px;
          }
        }
      }
      & > div:last-child {
        flex-direction: column;
        & > a {
          width: 100%;
          max-width: 340px;
        }
      }
    }
  }
`;
export default DrinksContent;
